<template>
  <div>
    <p>I study…</p>
    <p>IoT (Internet of Things) @ <a id="hyperlink" href="https://www.hfg-gmuend.de/" target="_blank">HfG
        Schwäbisch Gmünd</a>.</p>
    <p>You can find more information about my course and the projects at it's <a id="hyperlink"
        href="https://iot.hfg-gmuend.de/" target="_blank">portfolio</a>. Or take a look at my some of my favourite projects right <router-link id="hyperlink" to="/projects">here</router-link>.</p>
    <p><router-link id="back" title="◄ go back" to="/">◄ back</router-link></p>
  </div>
</template>

<script>
  export default {
    name: 'Studies',
  }
</script>

<style scoped>
p {
  color: #3b3b3b;
  font-size: 300%;
  margin: 0;
}
</style>